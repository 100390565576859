import { gsap } from 'gsap';

import { inview } from '../../utils/inview';

export default class Image {
  constructor() {
    this.el = document.querySelectorAll('.s-image');
  }

  mounted() {
    gsap.ticker.add(this.onRaf);

    this.init();
  }

  destroyed = () => {
    gsap.ticker.remove(this.onRaf);
  }

  init = () => {
    this.el.forEach((image) => {
      if (image.querySelector('.contained')) {
        gsap.set(
          image.querySelector('img'),
          { autoAlpha: 0, yPercent: 10 },
        )
      }
    });
  }

  onRaf = () => {
    this.el.forEach((image) => {
      if (inview(image, window.innerHeight, 0) && !image.inview) {
        image.inview = true;

        if (image.querySelector('.contained')) {
          gsap.to(
            image.querySelector('img'),
            { autoAlpha: 1, yPercent: 0, duration: 2, ease: 'expo.out' }
          )
        }
      }
    });
  }
}
