import { gsap } from 'gsap';
import Sniffer from 'sniffer';

import * as emitter from 'tiny-emitter/instance';

import { lerp } from '../../utils/math';

export default class Cursor {
  constructor() {
    this.el = document.querySelector('.a-cursor');

    this.sphere = this.el.querySelector('.cursor-sphere');
    this.indicator = this.el.querySelector('.cursor-indicator');

    this.inside = this.el.querySelector('.sphere-inside');

    this.icon = this.el.querySelector('.indicator-icon');
    this.label = this.el.querySelector('.indicator-label');

    this.target = [0, 0];
    this.current = [0, 0];
  }

  mounted() {
    if (Sniffer.isDevice) {
      this.el.remove();
      return;
    };

    gsap.ticker.add(this.onRaf);

    this.getButtons();
    this.addEvents();

    gsap.set(this.inside, { scale: 0.2 });

    emitter.on('cursor: init', this.init);
  }

  init = () => {
    if (Sniffer.isDevice) return;
    
    this.removeEvents();
    this.addEvents();
  }

  getButtons() {
    this.cLabel = document.querySelectorAll('[data-cursor-label]');
    this.cLink = document.querySelectorAll('[data-cursor="link"]');
    this.cImage = document.querySelectorAll('[data-cursor="image"]');
  }

  addEvents = () => {
    window.addEventListener('mousemove', this.onMouseMove);

    this.cLabel.forEach(label => label.addEventListener('mouseenter', this.enterLabel));
    this.cLabel.forEach(label => label.addEventListener('mouseleave', this.leaveLabel));

    this.cLink.forEach(link => link.addEventListener('mouseenter', this.enterLink));
    this.cLink.forEach(link => link.addEventListener('mouseleave', this.leaveLink));

    this.cImage.forEach(image => image.addEventListener('mouseenter', this.enterImage));
    this.cImage.forEach(image => image.addEventListener('mouseleave', this.leaveImage));
  }

  removeEvents = () => {
    window.removeEventListener('mousemove', this.onMouseMove);

    this.cLabel.forEach(label => label.removeEventListener('mouseenter', this.enterLabel));
    this.cLabel.forEach(label => label.removeEventListener('mouseleave', this.leaveLabel));

    this.cLink.forEach(link => link.removeEventListener('mouseenter', this.enterLink));
    this.cLink.forEach(link => link.removeEventListener('mouseleave', this.leaveLink));

    this.cImage.forEach(image => image.removeEventListener('mouseenter', this.enterImage));
    this.cImage.forEach(image => image.removeEventListener('mouseleave', this.leaveImage));

    this.getButtons();
  }

  enterLink = () => {
    this.el.classList.add('is-link');

    gsap.to(this.inside, { scale: 0.4, duration: 0.2 });
  }

  leaveLink = () => {
    this.el.classList.remove('is-link');

    gsap.to(this.inside, { scale: 0.2, duration: 0.2 });
  }

  enterLabel = (el) => {
    this.el.classList.add('is-label');

    gsap.to(this.inside, { scale: 1, duration: 0.2 });
    gsap.to(this.label, { autoAlpha: 1, scale: 1, duration: 0.2 });

    this.label.innerHTML = el.target.dataset.cursorLabel;
  }

  leaveLabel = () => {
    this.el.classList.remove('is-label');

    gsap.to(this.inside, { scale: 0.2, duration: 0.2 });
    gsap.to(this.label, { autoAlpha: 0, scale: 0, duration: 0.2 });
  }

  enterImage = () => {
    this.el.classList.add('is-image');

    gsap.to(this.inside, { scale: 0.6, duration: 0.2 });
    gsap.fromTo(
      this.icon.querySelector('svg'),
      { xPercent: -200, autoAlpha: 0 },
      { xPercent: 0, autoAlpha: 1 }
    );
  }

  leaveImage = () => {
    this.el.classList.remove('is-image');

    gsap.to(this.inside, { scale: 0.2, duration: 0.2 });
    gsap.fromTo(
      this.icon.querySelector('svg'),
      { xPercent: 0, autoAlpha: 1 },
      { xPercent: 200, autoAlpha: 0, ease: 'expo.out' }
    );
  }

  onMouseMove = ({ clientX: x, clientY: y }) => {
    this.target = [x, y];
  }

  onRaf = () => {
    this.current = [
      lerp(this.current[0], this.target[0], 0.1),
      lerp(this.current[1], this.target[1], 0.1)
    ];

    this.sphere.style.transform = `
      translate3d(
        ${this.current[0]}px,
        ${this.current[1]}px,
        0
      )
    `;

    this.indicator.style.transform = `
      translate3d(
        ${this.current[0]}px,
        ${this.current[1]}px,
        0
      )
    `;
  }
}
