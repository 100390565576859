import { gsap } from 'gsap';
import { SplitText } from 'gsap/SplitText';

gsap.registerPlugin(SplitText);

export default class HeaderHome {
  constructor() {
    this.el = document.querySelector('.header-text');

    this.graphic = this.el.querySelectorAll('.graphic path');
    this.heading = this.el.querySelectorAll('.line');
    this.text = this.el.querySelector('.text');
  }

  mounted() {
    document.fonts.ready.then(this.enter);
  }
  
  destroyed = () => { }

  enter = () => {
    gsap.fromTo(
      this.heading,
      { autoAlpha: 0, y: window.innerWidth * 0.05 },
      { autoAlpha: 1, y: 0, duration: 2, stagger: 0.2, ease: 'expo.out' }
    );

    gsap.fromTo(
      this.graphic,
      { scale: 0, rotate: -90 },
      { scale: 1, rotate: 0, duration: 3, stagger: 0.1, ease: 'expo.out' },
    );

    gsap.set(this.graphic, { autoAlpha: 1 });

    if (this.text) {
      const splitText = new SplitText(this.text, {
        type: 'lines',
      });

      gsap.fromTo(
        splitText.lines,
        { autoAlpha: 0, y: window.innerWidth * 0.02 },
        { autoAlpha: 1, y: 0, duration: 2, stagger: 0.2, delay: 0.5, ease: 'expo.out' }
      );

      gsap.set(this.text, { autoAlpha: 1 });
    }
  }
}
