// store
import { app } from '../utils/store';

// components
import ApplyNow from '../components/apply-now/apply-now';
import Button from '../components/button/button';
import Dragger from '../components/dragger/dragger';
import Footer from '../components/footer/footer';
import Form from '../components/form/form';
import HeaderHome from '../components/header-home/header-home';
import HeaderText from '../components/header-text/header-text';
import HeadingSection from '../components/heading-section/heading-section';
import Image from '../components/image/image';
import OurBrands from '../components/our-brands/our-brands';
import Pillars from '../components/pillars/pillars';
import SocialShare from '../components/social-share/social-share';

// ui
import Hud from '../utils/hud';
import Scroll from '../utils/scroll';

// components
export default class Components {
  constructor() {
    this.applyNow = document.querySelector('.s-apply-now');
    this.button = document.querySelector('[data-button]');
    this.dragger = document.querySelectorAll('.s-dragger');
    this.footer = document.querySelector('.a-footer');
    this.form = document.querySelector('.s-form');
    this.headerHome = document.querySelector('.header-home');
    this.headerText = document.querySelector('.header-text');
    this.headingSection = document.querySelector('.heading-section');
    this.image = document.querySelector('.s-image');
    this.ourBrands = document.querySelector('.s-our-brands');
    this.pillars = document.querySelector('.s-pillars');
    this.socialShare = document.querySelector('.social-share');
  }

  destroyed = () => {
    app.hud.destroyed();
    app.scroll.destroyed();

    if (this.applyNow) { this.applyNow.destroyed(); }

    if (this.button) { this.button.destroyed(); }
    if (this.dragger) {
      this.draggerArray.forEach((dragger) => {
        dragger.destroyed();
      });
    }
    if (this.footer) { this.footer.destroyed(); }
    if (this.form) { this.form.destroyed(); }
    if (this.headerHome) { this.headerHome.destroyed(); }
    if (this.headerText) { this.headerText.destroyed(); }
    if (this.headingSection) { this.headingSection.destroyed(); }
    if (this.image) { this.image.destroyed(); }
    if (this.ourBrands) { this.ourBrands.destroyed(); }
    if (this.pillars) { this.pillars.destroyed(); }
    if (this.socialShare) { this.socialShare.destroyed(); }
  }

  mounted = () => {
    app.hud = new Hud();
    app.hud.mounted();

    app.scroll = new Scroll();
    app.scroll.mounted();

    if (this.applyNow) {
      this.applyNow = new ApplyNow();
      this.applyNow.mounted();
    }

    if (this.button) {
      this.button = new Button();
      this.button.mounted();
    }

    this.draggerArray = [];

    if (this.dragger) {
      this.dragger.forEach((dragger) => {
        dragger = new Dragger(dragger);
        dragger.mounted();
        this.draggerArray.push(dragger);
      });
    }

    if (this.footer) {
      this.footer = new Footer();
      this.footer.mounted();
    }

    if (this.form) {
      this.form = new Form();
      this.form.mounted();
    }

    if (this.headerHome) {
      this.headerHome = new HeaderHome();
      this.headerHome.mounted();
    }

    if (this.headerText) {
      this.headerText = new HeaderText();
      this.headerText.mounted();
    }

    if (this.headingSection) {
      this.headingSection = new HeadingSection();
      this.headingSection.mounted();
    }

    if (this.image) {
      this.image = new Image();
      this.image.mounted();
    }

    if (this.ourBrands) {
      this.ourBrands = new OurBrands();
      this.ourBrands.mounted();
    }

    if (this.pillars) {
      this.pillars = new Pillars();
      this.pillars.mounted();
    }

    if (this.socialShare) {
      this.socialShare = new SocialShare();
      this.socialShare.mounted();
    }
  }
}