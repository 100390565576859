import { gsap } from 'gsap';
import Sniffer from 'sniffer';

import { inview } from '../../utils/inview';

export default class OurBrands {
  constructor() {
    this.el = document.querySelector('.s-our-brands');

    this.card = this.el.querySelectorAll('.card');
    this.text = this.el.querySelectorAll('.text');
    this.heading = this.el.querySelectorAll('.heading');
    this.overlay = this.el.querySelectorAll('.overlay');
  }

  mounted() {
    gsap.ticker.add(this.onRaf);

    window.addEventListener('resize', this.onResize, { passive: true });

    this.onResize();

    if (Sniffer.isPhone) return;

    this.addEvents();
    this.init();
  }

  destroyed = () => {
    gsap.ticker.remove(this.onRaf);

    window.removeEventListener('resize', this.onResize, { passive: true });
  }

  onResize = () => {
    this.windowHeight = window.innerHeight;
  }

  onRaf = () => {
    if (inview(this.el, this.windowHeight / 2, 0)) {
      gsap.fromTo(
        this.card,
        { autoAlpha: 0, yPercent: 50 },
        { autoAlpha: 1, yPercent: 0, ease: 'expo.out', stagger: 0.1, duration: 1.5 }
      );

      this.destroyed();
    }
  }

  init = () => {
    this.card.forEach((card, i) => {
      const y = card.querySelector('.text').clientHeight;

      gsap.set(this.text[i], { y, autoAlpha: 0, });
    });
  }

  addEvents = () => {
    this.card.forEach((card, i) => {
      card.addEventListener('mouseenter', () => {
        const y = card.querySelector('.text').clientHeight;

        gsap.to(
          this.heading[i],
          { y: -y, duration: 1, ease: 'power3.out' }
        );

        gsap.to(
          this.text[i],
          { y: 0, autoAlpha: 1, duration: 1, ease: 'power3.out' }
        );

        gsap.to(
          this.overlay[i],
          { autoAlpha: 1, duration: 1, ease: 'power3.out' }
        );
      });

      card.addEventListener('mouseleave', () => {
        const y = card.querySelector('.text').clientHeight;

        gsap.to(
          this.heading[i],
          { y: 0, duration: 1, ease: 'power3.out' }
        );

        gsap.to(
          this.text[i],
          { y, autoAlpha: 0, duration: 1, ease: 'power3.out' }
        );

        gsap.to(
          this.overlay[i],
          { autoAlpha: 0, duration: 1, ease: 'power3.out' }
        );
      });
    });
  }
}
