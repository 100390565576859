import { gsap } from 'gsap';

import { progress } from '../../utils/math';
import { inview } from '../../utils/inview';

export default class Promises {
  constructor() {
    this.el = document.querySelector('.s-pillars');

    this.block = this.el.querySelectorAll('.block');

    this.timeline = [];
  }

  mounted() {
    this.onResize();

    gsap.ticker.add(this.onRaf);
  }

  destroyed = () => {
    gsap.ticker.remove(this.onRaf);
  }

  onRaf = () => {
    this.block.forEach((el, i) => {
      if (inview(el, this.windowHeight, 0)) {
        this.setProgress(el, i);
      }
    });
  }

  onResize = () => {
    this.windowHeight = window.innerHeight;

    this.setAnimation();
  }

  setProgress = (el, i) => {
    const bounds = el.getBoundingClientRect();

    const value = -bounds.top + this.windowHeight;
    const max = bounds.height + this.windowHeight;

    this.progress = progress(value, 0, max);

    this.timeline[i].progress(this.progress);
  }

  setAnimation = () => {
    this.block.forEach((el, i) => {
      const scrollTl = gsap.timeline({ paused: true, defaults: { ease: 'none' } });
      const element = el.querySelector('.element');

      let yPercent = 50;
      let rotation = 45;
      let direction = -1;

      if (i === 1) {
        direction = 1;
      }

      scrollTl
        .fromTo(
          element,
          { yPercent, direction, rotate: 0 },
          { yPercent: 0, rotate: rotation * direction }
        );

      this.timeline.push(scrollTl);
    });
  }
}
