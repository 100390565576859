import { app } from '../../utils/store';

export default class ApplyNow {
  constructor() {
    this.el = document.querySelector('.s-apply-now');
  }

  mounted() {
    const url = window.location.href;
    const path = url.split('/').filter(segment => segment.trim().length > 0);
    const job = path[3];
    
    app.recruitee = job;
  }

  destroyed = () => {}
}
