import Sniffer from 'sniffer';
import * as emitter from 'tiny-emitter/instance';

export default class HeaderHome {
  constructor() {
    this.el = document.querySelector('.header-home');

    this.button = this.el.querySelectorAll('.button');
  }

  mounted() {
    this.addEvents();
  }

  destroyed = () => {
    this.removeEvents();
  }

  addEvents = () => {
    if (Sniffer.isPhone) {
      this.button[1].addEventListener('click', this.openPlayer);
    } else {
      this.el.addEventListener('click', this.openPlayer);
    }
  }

  removeEvents = () => {
    if (Sniffer.isPhone) {
      this.button[1].removeEventListener('click', this.openPlayer);
    } else {
      this.el.removeEventListener('click', this.openPlayer);
    }
  }

  openPlayer = () => {
    const src = this.el.querySelector('[data-video]').dataset.video;

    emitter.emit('video-player: openPlayer', src);
  }
}
