import { gsap } from 'gsap';

export default class Button {
  constructor() {
    this.button = document.querySelectorAll('[data-button]');
  }

  mounted() {
    this.button.forEach((button) => {
      const line = button.querySelector('.line');
      const label = button.querySelector('.label');
      const plus = button.querySelector('.plus');
      const arrow = button.querySelector('.arrow');
      const sphere = button.querySelector('.sphere');

      button.addEventListener('mouseenter', () => {
        gsap.fromTo(
          plus,
          { autoAlpha: 1, scale: 1, xPercent: 0 },
          { autoAlpha: 0, scale: 0, xPercent: 100, duration: 0.5 }
        );
        gsap.fromTo(
          arrow,
          { autoAlpha: 0, xPercent: -100 },
          { autoAlpha: 1, xPercent: 0, duration: 0.5 }
        );
        gsap.fromTo(
          sphere,
          { scale: 1, rotate: 0 },
          { scale: 1.2, rotate: 90, duration: 0.5 }
        );
        gsap.fromTo(
          [line, label],
          { xPercent: 0 },
          { xPercent: 5, duration: 0.5 }
        );
      });

      button.addEventListener('mouseleave', () => {
        gsap.fromTo(
          plus,
          { autoAlpha: 0, scale: 0, xPercent: 100 },
          { autoAlpha: 1, scale: 1, xPercent: 0, duration: 0.5 }
        );
        gsap.fromTo(
          arrow,
          { autoAlpha: 1, xPercent: 0 },
          { autoAlpha: 0, xPercent: -100, duration: 0.5 }
        );
        gsap.fromTo(
          sphere,
          { scale: 1.2 },
          { scale: 1, duration: 0.5 }
        );
        gsap.fromTo(
          [line, label],
          { xPercent: 5 },
          { xPercent: 0, duration: 0.5 }
        );
      });
    });
  }

  destroyed = () => { }
}
