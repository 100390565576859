// styles
import './index.scss';

// store
import { app } from './utils/store';

// emitter
import * as emitter from 'tiny-emitter/instance';

// highway
import Highway from '@dogstudio/highway';

// sniffer
import Sniffer from 'sniffer';

// renderers
import Renderer from './renderers/renderer';

// transitions
import Default from './transitions/default';

// ui
import Cursor from './components/cursor/cursor';
import Logo from './components/logo/logo';
import Menu from './components/menu/menu';
import Nav from './components/nav/nav';
import Notification from './components/notification/notification';
import VideoPlayer from './components/video-player/video-player';

// sniffer
Sniffer.addClasses(document.documentElement);

app.cursor = new Cursor();
app.cursor.mounted();

app.logo = new Logo();
app.logo.mounted();

app.menu = new Menu();
app.menu.mounted();

app.nav = new Nav();
app.nav.mounted();

app.notification = new Notification();
app.notification.mounted();

app.videoPlayer = new VideoPlayer();
app.videoPlayer.mounted();

// recruitee
app.recruitee = 'open-application';

// core
app.highway = new Highway.Core({
  renderers: {
    renderer: Renderer
  },
  transitions: {
    default: Default,
  }
});

app.highway.on('NAVIGATE_IN', ({ to, location }) => { });

app.highway.on('NAVIGATE_END', ({ to, from, trigger, location }) => {
  emitter.emit('nav: setActive');

  emitter.emit('logo: hideMonogram');
  emitter.emit('logo: showWordmark');

  if (Sniffer.isPhone) return;

  emitter.emit('nav: hideBurger');
  emitter.emit('nav: showButtons');

  if (Sniffer.isTablet) return;

  emitter.emit('cursor: init');
});

// vh
let vh = window.innerHeight * 0.01;

document.documentElement.style.setProperty('--vh', `${vh}px`);

window.addEventListener('resize', () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});