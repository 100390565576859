import { gsap } from 'gsap';
import Sniffer from 'sniffer';

import { progress } from '../../utils/math';
import { inview } from '../../utils/inview';

export default class Footer {
  constructor() {
    this.el = document.querySelector('.a-footer');

    this.container = this.el.querySelector('.container');
  }

  mounted() {
    if (Sniffer.isDevice) return;

    window.addEventListener('resize', this.onResize, { passive: true });

    gsap.ticker.add(this.onRaf);

    this.onResize();
  }

  destroyed = () => {
    if (Sniffer.isDevice) return;

    window.removeEventListener('resize', this.onResize, { passive: true });

    gsap.ticker.remove(this.onRaf);
  }

  onRaf = () => {
    if (inview(this.el, window.innerHeight, 0)) {
      this.setProgress();
    }
  }

  onResize = () => {
    this.windowHeight = window.innerHeight;

    this.setAnimation();
  }

  setProgress = () => {
    const bounds = this.el.getBoundingClientRect();

    const value = -bounds.top + this.windowHeight;
    const max = bounds.height;

    this.progress = progress(value, 0, max);
    this.scrollTl.progress(this.progress);
  }

  setAnimation = () => {
    this.scrollTl = gsap.timeline({ paused: true });

    this.scrollTl
      .fromTo(
        this.container,
        { yPercent: -50, autoAlpha: 0 },
        { yPercent: 0, autoAlpha: 1, ease: 'none' }, 0
      );
  }
}
