import { app } from '../../utils/store';

export default class Form {
  constructor() {
    this.el = document.querySelector('.s-form');

    this.form = this.el.querySelector('form');

    this.formspree = this.el.querySelector('#formspree');
  }

  mounted() {
    if (this.formspree) {
      this.form.addEventListener("submit", this.onSubmitFormSpree);
    } else {
      this.setParams();
      this.setOption();
    }
  }

  destroyed = () => { }

  getParams() {
    const urlParams = new URLSearchParams(window.location.search);
    const params = {};

    for (const [key, value] of urlParams.entries()) {
      params[key] = value;
    }

    return params;
  }

  setParams() {
    const allParams = this.getParams();

    if (Object.keys(allParams).length > 0) {
      app.recruitee = allParams.id;
    }
  }

  setOption() {
    const selectElement = this.form.querySelector("#option");
    const valueToCheck = app.recruitee;
    const optionValues = Array.from(selectElement.options).map(option => option.value);

    if (optionValues.includes(valueToCheck)) {
      selectElement.value = valueToCheck;
    } else {
      selectElement.value = optionValues[0];
    }
  }

  async onSubmitFormSpree(e) {
    e.preventDefault();

    const form = e.target;
    const status = form.querySelector('.status');

    const data = new FormData(e.target);

    fetch(e.target.action, {
      method: form.method,
      body: data,
      headers: {
        'Accept': 'application/json'
      }
    }).then(response => {
      if (response.ok) {
        app.highway.redirect(`${document.location.origin}/thank-you/`);
      } else {
        response.json().then(data => {
          if (Object.hasOwn(data, 'errors')) {
            status.innerHTML = data["errors"].map(error => error["message"]).join(", ")
          } else {
            status.innerHTML = "Oops! There was a problem submitting your form"
          }
        })
      }
    }).catch(error => {
      console.log(error);
    });
  }
}
